import { AppstoreAddOutlined } from "@ant-design/icons"
import { Button, Card, DatePicker, Flex, Form, Input, Modal, Radio, Select, notification } from "antd"
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import SeekSolutionApi from "../../../utils/SeekSolutionApi";
import TaskList from "./TaskListing";
import FileModal from "./FileModal";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TasksViewer = ({ company_id }) => {
    const formRef = useRef(null);
    const [form] = Form.useForm();
    const [addTaskModal, setAddTaskModal] = React.useState(false);
    const [taskId, setTaskId] = useState("")
    const [loading, setLoading] = React.useState(false);
    const [tasks, setTasks] = React.useState([]);
    const [parentId, setParentID] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const [fileModalVisible, setFileModalVisible] = useState(false)
    const [description, setDescription] = useState('');

    const onFinish = (values) => {
        // Add the WYSIWYG content to the form values manually
        const formData = {
            ...values,
            description: description // Add the WYSIWYG content here
        };
        console.log('Form Submitted:', formData);
    };

    const onFile = (taskId) => {
        setTaskId(taskId)
        setFileModalVisible(true)
       
      }


    const [state, setState] = React.useState({
        "id": company_id,
        "users": [],
        "company_name": "",
        "company_logo": "",
        "company_code": null,
        "br_number": null,
        "ci_number": null,
        "tin_number": null,
        "account_officer_name": null,
        "account_officer_email": null,
        "company_secretarial_name": null,
        "company_secretarial_email": null,
        "manager_name": null,
        "associate_auditor": null,
        "last_audited_financial_statement": null,
        "incorporation_date": null,
        "last_agm_signed": null,
        "last_annual_return": null,
        "last_profit_tax_return": null,
        "last_business_registration": null,
        "next_audited_financial_statement_deadline": null,
        "next_accounting_period_due": null,
        "next_agm_deadline": null,
        "next_annual_return_due": null,
        "profit_tax_return_deadline": null,
        "next_business_registration_renewal_due": null,
        "notes": null,
        "services": []
    })

    const openNotification = (messagedata) => {
        api.open({
            message: 'Alert',
            description: messagedata,
            placement: "top",
            duration: 2,
        });
    };

    SeekSolutionApi.setToken(localStorage.getItem("token"))

    const handleEmails = async (values) => {
        console.log("pop Up state : ", parentId);
        console.log("pop Up values : ", values);
        if (values.title && values.priority && values.due_date && values.description && values.assigned_to) {
            await handleTask(values, true)
        }
        try {
            if (parentId) {
                console.log("pop Up state12 : ", parentId);
                const apiRes = await SeekSolutionApi.Tasks.email({ id: parentId });
                console.log("Email Send apiRes", apiRes);
                setParentID(null);
            } else {
                console.log("parentId is empty. Cannot send email.");
            }
        } catch (error) {
            console.log("Email Send error", error);
        }
        setAddTaskModal(false);
    };

    const deleteTask = async () => {
        console.log("pop Up state : ", parentId);
        
        try {
            if (parentId) {
                console.log("pop Up state12 : ", parentId);
                const apiRes = await SeekSolutionApi.Tasks.delete(parentId);
                openNotification("Task deleted successfully.");
                setParentID(null);
                initialiseCompanyTasks()
                setLoading(false);
            } else {
                console.log("parentId is empty. Cannot delete.");
            }
        } catch (error) {
            console.log("Email Send error", error);
        }
        setAddTaskModal(false);
    };


    const handleTask = async (values, isParent = false) => {
        console.log("handleTask values", values);
        console.log("handleTask isParent", moment(values.due_date.toDate()).format("YYYY-MM-DD"));
        if (values.title && values.priority && values.due_date && values.description && values.assigned_to) {
            setLoading(true);
            const items = {
                id: new Date().getTime().toString(),
                ...values,
                due_date: moment(values.due_date.toDate()).format("YYYY-MM-DD"),
                "status": "To Do",
                "company": company_id
            }

            if (isParent == false) {
                try {
                    const apiRes = await SeekSolutionApi.Tasks.create(items)
                    const id = apiRes.data.id;
                    setParentID(id);
                    //formRef.current.resetFields();
                    openNotification("Task added successfully.");
                } catch (error) {
                    console.log("handleNewTask error ", error);
                }
            } 
            // else {
            //     if (parentId) {
            //         try {
            //             const apiRes = await SeekSolutionApi.Tasks.childTaskCreate({ task_id: parentId, ...items })
            //             formRef.current.resetFields();
            //             openNotification("Child task added successfully");

            //         } catch (error) {
            //             console.log("handleNewTask error ", error);
            //         }

            //     }
            //     else {
            //         openNotification("Please save the task first");
            //     }
            // }
            initialiseCompanyTasks()
            setLoading(false);
        }
        else {
            {
                parentId &&
                    openNotification("Please input all fields.");
            }
        }
    }

    const updateTask = async (id, items) => {
        try {
            await SeekSolutionApi.Tasks.update(id, items)
        } catch (error) {

        }
    }

    const initialiseCompanyDetails = async () => {
        try {
            const apiRes = await SeekSolutionApi.Company.get(company_id)
            // console.log("initialiseCompanyDetails apiRes", apiRes);
            setState(apiRes)
        } catch (error) {
            console.log("initialiseCompanyDetails error", error);
        }
    }
    const initialiseCompanyTasks = async () => {
        try {
            const apiRes = await SeekSolutionApi.Company.tasks(company_id)
            // console.log("initialiseCompanyDetails apiRes", apiRes);
            setTasks(apiRes)
        } catch (error) {
            console.log("initialiseCompanyDetails error", error);
        }
    }


    React.useEffect(() => {
        initialiseCompanyDetails()
        initialiseCompanyTasks()
        // eslint-disable-next-line
    }, [company_id])
    return (<> {contextHolder}<Flex >
        <Card title="Tasks" className="Text-text" extra={<AppstoreAddOutlined onClick={() => setAddTaskModal(true)} />} style={{ width: "100%" }}>
            <TaskList key={1} tasks={tasks} setTasks={setTasks} updateTask={updateTask} setAddTaskModal={setAddTaskModal} users={state.users} initialiseCompanyTasks={initialiseCompanyTasks} />
        </Card>

        <Modal
            title={`${!parentId ? "Add another task" : `Add child task of Parent id : ${parentId}`}`}
            footer={false}
            open={addTaskModal}
            onCancel={() => {
                setAddTaskModal(false)
                setParentID('');
                formRef.current.resetFields()
            }}
            width={1000}
        >

            <Form
                ref={formRef}
                form={form}
                onFinish={onFinish}
                name="basic"
                autoComplete="off"
                // onFinish={handleAddTask}
                // size="small"
                labelCol={{ span: 4 }}
            // layout="vertical"
            >
                <Form.Item
                    label="Title"
                    name="title"
                    rules={parentId ? [] : [{ required: true, message: 'Please input your title!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Category"
                    name="category"
                    rules={parentId ? [] : [{ required: true, message: 'Please input your category!' }]}
                    >
                        <Radio.Group className="radio-inline">
                            <Radio.Button value="SLCS Secretarial">SLCS Secretarial</Radio.Button>
                            <Radio.Button value="SLCS Accounting">SLCS Accounting</Radio.Button>
                            <Radio.Button value="Others">Others</Radio.Button>
                            <Radio.Button value="Sales Pipeline">Sales Pipeline</Radio.Button>
                        </Radio.Group>
                    </Form.Item>


                

                <Form.Item
                    label="Priority"
                    name="priority"
                    rules={parentId ? [] : [{ required: true, message: 'Please select task priority!' }]}
                >
                    <Radio.Group >
                        <Radio.Button value="Low">Low</Radio.Button>
                        <Radio.Button value="Medium">Medium</Radio.Button>
                        <Radio.Button value="High" >High</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Due date"
                    name="due_date"
                    rules={parentId ? [] : [{ required: true, message: 'Please input due date!' }]}
                >
                    <DatePicker
                    format={"YYYY-MM-DD"}
                    />
                </Form.Item>
                <Form.Item
                    label="Frequency"
                    name="frequency"
                >
                    <Radio.Group>
                        <Radio.Button value="Daily">Daily</Radio.Button>
                        <Radio.Button value="Weekly">Weekly</Radio.Button>
                        <Radio.Button value="Monthly" >Monthly</Radio.Button>
                        <Radio.Button value="Annually" >Annually</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={parentId ? [] : [{ required: true, message: 'Please input your description!' }]}

                >
                    {/* <Input.TextArea rows={10} /> */}
                    <ReactQuill
                        theme="snow"
                        value={description} // Bind value from state
                        onChange={setDescription} // Update the state on change
                        style={{ height: '150px' }}
                    />
                </Form.Item>
                <br />
                <Form.Item
                    label="Assigned to"
                    name="assigned_to"
                    rules={parentId ? [] : [{ required: true, message: 'Please input Assigned to!' }]}
                >
                    <Select
                        mode="multiple"
                        options={state.users.map((res) => (
                            { value: res.id, label: `${res.first_name} ${res.last_name}` }
                        ))}
                    />

                </Form.Item>

                {/* {parentId &&
                    <div className="email_Flag">
                        
                    </div>
                } */}


                <div className="task_btns">
                    {!parentId &&
                        <Button
                            htmlType="submit"
                            loading={loading}
                            onClick={() => handleTask(formRef.current.getFieldsValue())}
                            block
                        >
                            Save Task
                        </Button>
                    }

                    
                    {
                        parentId &&
                        <Button
                            htmlType="submit"
                            loading={loading}
                            block
                            onClick={() => onFile(parentId)}
                        >
                            Add Files
                        </Button>
                    }
                    {
                        parentId &&
                        
                            
                        <Button
                            htmlType="submit"
                            loading={loading}
                            block
                            onClick={() => handleEmails(formRef.current.getFieldsValue())}
                        >
                            Send Email
                        </Button>
                        
                    }
                    {
                        parentId &&
                        
                            
                        <Button
                            htmlType="submit"
                            loading={loading}
                            block
                            onClick={() => deleteTask()}
                        >
                            Delete Task
                        </Button>
                        
                    }
                    

                </div>

            </Form>
        </Modal>

    </Flex>
    <FileModal
      title={"YOUR FILES"}
      visible={fileModalVisible}
      setVisible={setFileModalVisible}
      taskId={taskId}
    />
    </>
    )
}

export default TasksViewer