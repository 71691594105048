import _superagent from "superagent"
import superagentPromise from "superagent-promise"

const superagent = superagentPromise(_superagent, global.Promise)
// const ROOT = 'http://127.0.0.1:8000/'
const ROOT = 'https://portal.shenlaroche.online/'
const API_ROOT = ROOT + `api/`

const responseBody = (res) => res.body

let token = null
const tokenPlugin = (req) => {
  if (token) {
    req.set("authorization", `token ${token}`)
  }
}

const requests = {
  del: (url, body) =>
    superagent.del(`${API_ROOT}${url}`).send(body).use(tokenPlugin).then(responseBody),
  get: (url) =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  patch: (url, body) =>
    superagent
      .patch(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
}

const Company = {
  get: (id) => requests.get(`company/${id}/`),
  tasks: (id) => requests.get(`task/?company_id=${id}`),
  list: () => requests.get(`company/`),
  reminder: (companyId) => requests.get(`reminder/${companyId}/`),
  update: (id, info) => requests.put(`company/${id}/`, info)

}

const Comments = {
  get: (id) => requests.get(`company/${id}/`),
  create: (items) => requests.post(`comment/`, items),
  edit: (id, items) => requests.patch(`comment/${id}/`, items),
  list: (taskId) => requests.get(`comment/?task_id=${taskId}`),
}
const Reminder = {
  get: (id) => requests.get(`reminder/${id}/`),
  create: (items) => requests.post(`reminder/`, items),
  edit: (id, items) => requests.patch(`reminder/${id}/`, items),
  list: () => requests.get(`reminder/`),
}

const Tasks = {
  create: (info) => requests.post("task/", info),
  update: (id, info) => requests.patch(`task/${id}/`, info),
  delete: (id) => requests.del(`task/${id}/`),
  email: (id) => requests.post("send_task_email/", id),
  childTaskCreate: (info) => requests.post("add_chaild_task/", info) 
}

const Files = {
  // create: (info) => requests.post("task/", info),
  // update: (id, info) => requests.patch(`task/${id}/`, info),
  // email: (id) => requests.post("send_task_email/", id),
  // childTaskCreate: (info) => requests.post("add_chaild_task/", info) 
  list: () => requests.get(`file/`),
}

const SeekSolutionApi = {
  token,
  Company,
  Comments,
  Reminder,
  Tasks,
  ROOT,
  Files,
  API_ROOT,
  setToken: (_token) => {
    token = _token;
  },
};


export default SeekSolutionApi;
