import { Avatar, Button, Flex, Form, Input, List, Popconfirm } from "antd"
import React from "react";
import SeekSolutionApi from "../../../utils/SeekSolutionApi";
import { useForm } from "antd/es/form/Form";
import { useUserContext } from "../../../Context/UserContext";


function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  }

function truncateText(texts, maxLength) {

    let text = texts.split('\n').filter(line => line.trim() !== '').join('\n')
    if (text.length > maxLength) {
        return `${text.substring(0, maxLength)}...`;
    }
    return text;
}

// Utility function to replace newlines with <br />
const formatCommentWithLineBreaks = (comment) => {
    return comment.split('\n').map((line, index) => (
        <span key={index}>{line}<br /></span> // Each line is wrapped in a <span> with a <br /> tag
    ));
};
  
const CommentViwer = ({ data }) => {
    SeekSolutionApi.setToken(localStorage.getItem("token"))
    const [user] = useUserContext();
    const [form] = useForm()
    const [state, setState] = React.useState([])
    const [dataLoading, setDataLoading] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [editMode, setEditMode] = React.useState(-1)
    const [editModeComment, setEditModeComment] = React.useState(-1)
    const inputRef = React.useRef(null);
    console.log("user",user);
    console.log("tasker",data)
    const createComments = async (values) => {
        setLoading(true)
        const items = {
            ...values,
            "task": data.id,
            "user": user.user.id
        }
        try {
            await SeekSolutionApi.Comments.create(items)
            setState([items, ...state])
            inputRef.current.focus({
                cursor: 'start',
            });
            form.resetFields()
            initialiseComments()

        } catch (error) {

        } finally {
            setLoading(false)
        }
    }
    const updateComments = async (item) => {
        setLoading(true)
        const items = {
            comment: editModeComment,
            "task": data.id,
            "user": user.user.id
        }
        try {
            await SeekSolutionApi.Comments.edit(item.id, items)

            let updated = state.map((singleComment) => {
                console.log("task", singleComment);
                if (singleComment.id.toString() === item.id?.toString()) {
                    singleComment["comment"] = editModeComment;
                }
                return singleComment;
            });
            setState([...updated]);
            setEditMode(-1)

        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const initialiseComments = async () => {
        try {
            setDataLoading(true)
            const apiRes = await SeekSolutionApi.Comments.list(data.id)
            console.log("initialiseComments apiRes", apiRes);
            setState(apiRes)
        } catch (error) {
            console.log("initialiseComments Error", error);
        } finally {
            setDataLoading(false)
        }
    }

    function truncateText(texts, maxLength) {
        
        let text = texts.split('\n').filter(line => line.trim() !== '').join('\n')
        if (text.length > maxLength) {
            return `${text.substring(0, maxLength)}...`;
        }
        return text;
    }


    React.useEffect(() => {
        initialiseComments()
        // eslint-disable-next-line 
    }, [data])

    return <Flex gap={"small"} vertical>
        <Form
            onFinish={createComments}
            layout="vertical"
            form={form}
        >
            <Form.Item
                label="Write your thoughts"
                name="comment"
                rules={[{ required: true, message: 'Please enter your comment!' }]}
            >
                <Input.TextArea ref={inputRef} />
            </Form.Item>
            <Button
                htmlType="submit"
                loading={loading}
                block
            >Add Comment</Button>
        </Form>

        <List
            // pagination={{ position: "bottom", align: "end" }}
            dataSource={state}
            loading={dataLoading}
            renderItem={(item, index) => (
                (index === editMode) ? <List.Item
                    actions={[<Popconfirm
                        title="Update your comment"
                        description="Are you sure to update this comment?"
                        onConfirm={() => updateComments(item)}
                        okText="Update"
                        okButtonProps={{ type: "default" }}
                        cancelButtonProps={{ type: "dashed", danger: true }}
                        cancelText="Cancel"
                    ><Button >Save</Button></Popconfirm>, <Button onClick={() => setEditMode(-1)} danger>Cancel</Button>]}
                >
                    <Input.TextArea defaultValue={item.comment} value={editModeComment} onChange={(e) => setEditModeComment(e.target.value)} />
                </List.Item> :
                    <List.Item
                    actions={[
                        // user.user.id === item.user && (
                        //     <>
                        //         <Button onClick={() => {
                        //             setEditMode(index)
                        //             setEditModeComment(item.comment)
                        //         }}>Edit</Button>
                        //     </>
                        // ),
                        item?.email_reply && (
                            <Button onClick={() => {
                                window.open("https://mail.google.com/mail/u/0/#search/\"" + encodeURIComponent(data.title) + "\"")
                            }}>Open MAIL</Button>
                        )
                    ]}
                >
                        <List.Item.Meta
                            avatar={<Avatar src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`} />}
                            title={`${item.name} commented on ${formatDate(item.created_date).toLocaleString()}`}
                            description={item?.email_reply ? <div>{formatCommentWithLineBreaks(truncateText(item.comment, 500))}</div> : item.comment}
                        />
                    </List.Item>
            )}
        />

    </Flex>
}

export default CommentViwer